/* TM colors */
$black: #000000;
$white: #ffffff;

$grey-light00: #303030;
$grey-light10: #666666;
$grey-light20: #969696;
$grey-light30: #bbbbbb;
$grey-light40: #e7e7e7;
$grey-light60: #ededed;
$grey-light50: #f4f4f4;
$blue-primary: #0a0082;
$separator: #dcdcdc;
$blue-terminus: #3971ff;
$yellow-terminus: #fff500;

/* RATP colors */
$vert-jade-ratp: #00c4b3;
$vert-jade-ratp-70: #00aa91;
$vert-jade-ratp-90: #008775;
$bleu-ratp: #050d9e;
$coquelicot: #e2231a;

// TM colors
$no-passenger: #ededf0;
//delay
$on-course: #ff7a00;
$on-day: #ff5b59;

// info line
$red-alert: #ff563f;
$red-info: #ff5b59;
$red-interrupted: #cc0328;
$red-interrupted-non-selected:rgba(204, 3, 40, .5);
$yellow-alert: #dc5c00;
$yellow-alert2: #FFB133;
$red-alert2: #DC2826;
$yellow-alert-non-selected: rgba(220, 92, 0, .5);
$yellow-info: #e0a818;
$yellow-info-non-selected: rgba(224, 168, 24, .5);
$green-info: #56bd45;
$green-light-info: rgba(86, 189, 69, 0.66);
$grey-20: #f5f5f6;
$grey-03: #303030;
$grey-04: #5D5C6D;


//attendance
$low-medium-attendance: #00aa91;
$high-attendance: #ff5a00;
$full-attendance: #e61200;
