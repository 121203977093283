/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'styles/variables/colors';
@import 'styles/variables/margins';
@import 'styles/variables/fonts';

body {
  --ion-font-family: $font-family !important;
  font-family: $font-family !important;
  font-size: $default-font-size;
  overflow: hidden;

  .modal-wrapper {
    box-shadow: none !important;
  }

  ion-backdrop {
    --backdrop-opacity: none !important;
    background: white !important;
  }

  ion-backdrop {
    &.sc-ion-action-sheet-md {
      --backdrop-opacity: 0.5 !important;
    }

    &.sc-ion-action-sheet-ios {
      --backdrop-opacity: 0.5 !important;
    }

    &.sc-ion-action-sheet-android {
      --backdrop-opacity: 0.5 !important;
    }
  }

  ion-button {
    text-transform: none;
  }

  ion-icon {
    font-size: 25px;
  }

  .separator {
    border-bottom: 1px solid $separator;
  }

  .wrapper-header {
    max-width: 1024px !important;
  }

  .wrapper-modal {
    width: 834px !important;
  }

  .wrapper-header-mobile {
    width: 100%;
  }

  .wrapper-modal-mobile {
    width: 100%;
  }

  .grey-background {
    background-color: $grey-light50;
    padding: 0;
  }

  .white-background {
    background-color: white;
  }

  .header-delimiter {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 999;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px;
    width: 30px;
    font-size: 6px;
    --background: #e7e7e7;
    --box-sizing: border-box;
    --border-radius: 50%;
    --box-shadow: none;

    ion-icon {
      color: #6a6a6a;
    }
  }
}

ion-card {
  border-radius: 4px;
  margin: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

  ion-card-content {
    padding: 8px 26px 8px 8px;

    .cartridge-type {
      font-size: $medium-text-font-size;
      font-weight: bold;
      color: #000000;
    }

    .cartridge-title {
      font-size: $medium-text-font-size;
      font-weight: normal;
      color: #9e9a9a;
    }

    .cartridge-title-mobile {
      font-size: $xsmall-text-font-size;
      font-weight: normal;
      color: #9e9a9a;
    }

    .cartridge-percentage {
      font-size: 44px;
      color: #303030;
      font-weight: 600;
    }

    .cartridge-percentage-mobile {
      font-size: 30px;
      color: #303030;
      font-weight: 600;
    }

    .cartridge-detail {
      font-size: 18px;
      color: black;
    }

    .cartridge-detail-mobile {
      font-size: 13px;
      color: black;
    }

    .col-detail {
      padding-top: 6px;
    }

    .red {
      color: $red-interrupted;
    }

    .orange {
      color: $yellow-alert;
    }

    .yellow {
      color: $yellow-info;
    }

    .green {
      color: $green-info;
    }
  }

  .percentage-symbol {
    font-size: 15px;
    font-weight: 600;
  }
}

.no-padding {
  padding: 0;
}

ion-modal {
  .modal-wrapper {
    top: 0;
    height: 100% !important;
    width: 100% !important;
  }
}

ion-alert {
  ion-backdrop {
    --backdrop-opacity: 0.8 !important;
    background: #000000 !important;
  }
  &.profile-default-line {
    font-weight: 600 !important;
  }
}

.title-wrapper {
  position: relative;

  .box-shadow-title {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.toast-custom-class {
  --border-radius: 3px;
  --border-style: solid;
  --button-color: #11d8af;
  --color: #ffffff;
  --background: #000000;
}

.auto-scroll-content {
  overflow: auto;
}

.profile-default-line .alert-title.sc-ion-alert-md {
  font-weight: 600 !important;
  color: #303030;
  font-family: $font-family;
}

.profile-default-line .alert-wrapper {
  .alert-button {
    color: $black;
    font-weight: 600;
    font-size: 12px !important;
  }
}

/* action sheet css should be in global.scss*/
.action-sheet {
  --button-color: black;
  --button-color-selected: black;
  --color: black;
  --backdrop-opacity: 0.6 !important;
}
.action-sheet-button.sc-ion-action-sheet-ios {
  font-size: 14px !important;
}
.action-sheet-container.sc-ion-action-sheet-ios {
  padding: 0 !important;
  font-size: 12px !important;
  color: $grey-light10;
}
.action-sheet-icon.sc-ion-action-sheet-md {
  margin-right: 12px !important;
}

body ion-backdrop.sc-ion-action-sheet-ios {
  --backdrop-opacity: 0.4 !important;
}
body ion-backdrop {
  background: $black !important;
}
.action-sheet-button.sc-ion-action-sheet-ios .action-sheet-icon.sc-ion-action-sheet-ios {
  font-size: 18px !important;
  margin-right: 5px !important;
}

ion-modal.disruption-detail::part(content) {
  --height: 100%;
  --width: 100%;
}
