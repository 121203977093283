$font-family: Roboto, 'Roboto', sans-serif;
$welcome-title-font-size: 24px;
$h1: 28px;
$h2: 18px;
$search-title-font-size: 34px;
$tablet-interval-percentage: 33px;
$modal-title-font-size: 38px;
$modal-body-font-size: 16px;
$icon-slider-font-size: 30px;
$icon-ratp-logo-size: 34px;
$title-font-size: 24px;
$icon-font-size: 20px;
$medium-icon-font-size: 26px;
$default-font-size: 13px;
$validation-font-size: 14px;
$medium-font-size: 14px;
$large-font-size: 18px;
$small-text-font-size: 11px;
$small-text-button-next-departure: 15px;
$xsmall-text-font-size: 10px;
$text-font-size: 13px;
$text-jumbotron-hello-size: 17px;
$text-jumbotron-message-size: 26px;
$medium-text-font-size: 12px;
$interval-text-font-size: 26px;
$counter-text-font-size: 13px;
$notification-font-size: 14px;
$input-font-size: 15px;
$dashboard-first-header-font-size: 13px;
$dashboard-second-header-font-size: 13px;
$notification-list-font-size: 16px;
